import { Action } from '@ngrx/store';
import { IBaseOneResponse, IGetManyResponse } from '../../shared/model/interface/crud-response-interface.model';
import { IFormSubmissionRequestBody, ILogbook, ILogbookTableQuery } from '../logbooks/logbooks.model';
import { ELogbookGridView } from '../../view/home/logbook-grid-view/logbook-grid-view.model';
import {
  IFormSubmission,
  IFormSubmissionParams,
  IHomeFormSubmission,
  ILogbookHomeDetail,
  IHomeSearchResult,
} from './home.model';
import { IGenericTreeNode, IHomeLogbook, ISidebarCategoryExpandedStatus } from '../../view/home/home.model';
import { IConfig } from '../user/model';
import { IResponse } from '../../shared/model/interface/generic-api-response.model';
import { IIssuerAndReason } from '../../shared/component/before-action-preparer/before-action-preparer.model';
import { IFormSubmissionUserAction, ITableHeader, IUserAction } from '../../../constants.model';
import { IFormVersion } from '../forms/forms.model';
import { IGetCurrentUserResponseData } from '../app/model';

export const FAVORITE_LOGBOOKS_DATA_LOADING = '[HOME] FAVORITE LOGBOOKS DATA LOAD';
export const FAVORITE_LOGBOOKS_DATA_LOADED = '[HOME] FAVORITE LOGBOOKS DATA LOADED';
export const ONGOING_LOGBOOKS_DATA_LOADING = '[HOME] ONGOING LOGBOOKS DATA LOAD';
export const ONGOING_LOGBOOKS_DATA_LOADED = '[HOME] ONGOING LOGBOOKS DATA LOADED';
export const NETWORK_LOGBOOKS_DATA_LOADING = '[HOME] NETWORK LOGBOOKS DATA LOAD';
export const NETWORK_LOGBOOKS_DATA_LOADED = '[HOME] NETWORK LOGBOOKS DATA LOADED';
export const CHILDREN_LOGBOOKS_DATA_LOADING = '[HOME] CHILDREN LOGBOOKS DATA LOAD';
export const CHILDREN_LOGBOOKS_DATA_LOADED = '[HOME] CHILDREN LOGBOOKS DATA LOADED';
export const GRID_VIEW_CHANGED = '[HOME] GRID VIEW CHANGED';
export const ADD_LOGBOOK_TO_FAVORITES_LOADING = '[HOME] ADD LOGBOOK TO FAVORITES LOADING';
export const ADD_LOGBOOK_TO_FAVORITES_LOADED = '[HOME] ADD LOGBOOK TO FAVORITES LOADED';
export const LOGBOOK_HOME_DETAIL_DATA_LOADING = '[HOME] LOGBOOK HOME DETAIL DATA LOADING';
export const LOGBOOK_HOME_DETAIL_DATA_LOADED = '[HOME] LOGBOOK HOME DETAIL DATA LOADED';
export const CANCEL_FORM_SUBMISSION = '[HOME] CANCEL FORM SUBMISSION';
export const CANCEL_FORM_SUBMISSION_COMPLETED = '[HOME] CANCEL FORM SUBMISSION COMPLETED';
export const GET_FORM_SUBMISSION_HISTORY = '[HOME] GET FORM SUBMISSION HISTORY';
export const GET_FORM_SUBMISSION_HISTORY_COMPLETED = '[HOME] GET FORM SUBMISSION HISTORY COMPLETED';
export const GET_FORM_SUBMISSION = '[HOME] GET FORM SUBMISSION';
export const GET_FORM_SUBMISSION_COMPLETED = '[HOME] GET FORM SUBMISSION COMPLETED';
export const UPDATE_FORM_SUBMISSION = '[HOME] UPDATE FORM SUBMISSION';
export const UPDATE_FORM_SUBMISSION_COMPLETED = '[HOME] UPDATE FORM SUBMISSION COMPLETED';
export const SET_NETWORK_LOGBOOKS_TREE = '[HOME] SET NETWORK LOGBOOKS TREE';
export const SET_SIDEBAR_CATEGORY_EXPANDED_STATUS = '[HOME] SET SIDEBAR CATEGORY EXPANDED STATUS';
export const SET_SIDEBAR_EXPANDED_STATUS = '[HOME] SET SIDEBAR EXPANDED STATUS';
export const GET_LOGBOOK_USER_ACTION_DATA = '[HOME] GET LOGBOOK USER ACTION DATA';
export const GET_LOGBOOK_USER_ACTION_DATA_COMPLETED = '[HOME] GET LOGBOOK USER ACTION DATA COMPLETED';
export const GET_FORM_USER_ACTION_DATA = '[HOME] GET FORM USER ACTION DATA';
export const GET_FORM_USER_ACTION_DATA_COMPLETED = '[HOME] GET FORM USER ACTION DATA COMPLETED';
export const GET_FORM_SUBMISSIONS = '[HOME] GET FORM SUBMISSIONS';
export const GET_FORM_SUBMISSIONS_COMPLETED = '[HOME] GET FORM SUBMISSIONS COMPLETED';
export const GET_LOGBOOK = '[HOME] GET LOGBOOK';
export const GET_LOGBOOK_COMPLETED = '[HOME] GET LOGBOOK COMPLETED';
export const SET_LOGBOOK_DETAIL_TO_PARENT_VIEW = '[HOME] SET LOGBOOK DETAIL TO PARENT VIEW';
export const SET_TABLE_SETTINGS = '[HOME] HOME TABLE SETTINGS SET';
export const CREATE_FORM_SUBMISSION = '[HOME] CREATE FORM SUBMISSION';
export const CREATE_FORM_SUBMISSION_COMPLETED = '[HOME] CREATE FORM SUBMISSION COMPLETED';
export const SUBMIT_FORM_SUBMISSION = '[HOME] SUBMIT FORM SUBMISSION';
export const SUBMIT_FORM_SUBMISSION_COMPLETED = '[HOME] SUBMIT FORM SUBMISSION COMPLETED';
export const SAVE_FORM_SUBMISSION = '[HOME] SAVE FORM SUBMISSION';
export const SAVE_FORM_SUBMISSION_COMPLETED = '[HOME] SAVE FORM SUBMISSION COMPLETED';
export const REDIRECT_TO_HOME = '[HOME] REDIRECT TO HOME';
export const GET_FORM_VERSION = '[HOME] GET FORM VERSION';
export const GET_FORM_VERSION_COMPLETED = '[HOME] GET FORM VERSION COMPLETED';
export const SIGN_DOCUMENT = '[HOME] SIGN DOCUMENT';
export const HOME_SEARCH = '[HOME] HOME SEARCH';
export const HOME_SEARCH_LOADED = '[HOME] HOME SEARCH LOADED';
export const GET_CURRENT_USER = '[HOME] Get Current User';
export const SET_HOME_USER_INFO = '[HOME] Set Home User Info';
export const FETCH_ERROR = '[HOME] FETCH ERROR';

export class FavoriteLogbooksDataLoading implements Action {
  readonly type = FAVORITE_LOGBOOKS_DATA_LOADING;

  constructor(public queryParams?: ILogbookTableQuery) {}
}

export class FavoriteLogbooksDataLoaded implements Action {
  readonly type = FAVORITE_LOGBOOKS_DATA_LOADED;

  constructor(public payload: IGetManyResponse<ILogbook>) {}
}

export class OngoingLogbooksDataLoading implements Action {
  readonly type = ONGOING_LOGBOOKS_DATA_LOADING;
}

export class OngoingLogbooksDataLoaded implements Action {
  readonly type = ONGOING_LOGBOOKS_DATA_LOADED;

  constructor(public payload: IGetManyResponse<ILogbook>) {}
}

export class NetworkLogbooksDataLoading implements Action {
  readonly type = NETWORK_LOGBOOKS_DATA_LOADING;

  constructor(public queryParams?: ILogbookTableQuery, public afterChangeParent?: boolean) {}
}

export class NetworkLogbooksDataLoaded implements Action {
  readonly type = NETWORK_LOGBOOKS_DATA_LOADED;

  constructor(public payload: IGetManyResponse<ILogbook>) {}
}

export class ChildrenLogbooksDataLoading implements Action {
  readonly type = CHILDREN_LOGBOOKS_DATA_LOADING;

  constructor(public parentId: number, public isGridView: boolean, public queryParams?: ILogbookTableQuery) {}
}

export class ChildrenLogbooksDataLoaded implements Action {
  readonly type = CHILDREN_LOGBOOKS_DATA_LOADED;

  constructor(public payload: IGetManyResponse<ILogbook>, public isGridView: boolean) {}
}

export class GridViewChanged implements Action {
  readonly type = GRID_VIEW_CHANGED;

  constructor(public payload: ELogbookGridView, public parentLogbook?: IHomeLogbook) {}
}

export class AddLogbookToFavoritesLoading implements Action {
  readonly type = ADD_LOGBOOK_TO_FAVORITES_LOADING;

  constructor(public payload: number, public isAdd: boolean) {}
}

export class AddLogbookToFavoritesLoaded implements Action {
  readonly type = ADD_LOGBOOK_TO_FAVORITES_LOADED;

  constructor(public payload: IResponse<{ meta: IConfig }>) {}
}

export class LogbookHomeDetailDataLoading implements Action {
  readonly type = LOGBOOK_HOME_DETAIL_DATA_LOADING;

  constructor(public logbookId: number, public params: IFormSubmissionParams) {}
}

export class LogbookHomeDetailDataLoaded implements Action {
  readonly type = LOGBOOK_HOME_DETAIL_DATA_LOADED;

  constructor(public payload: ILogbookHomeDetail) {}
}

export class CancelFormSubmission implements Action {
  readonly type = CANCEL_FORM_SUBMISSION;

  constructor(
    public logbookId: number,
    public formSubmissionId: number,
    public issuerAndReason: IIssuerAndReason,
    public dispatchHideLoader: boolean = false,
  ) {}
}

export class CancelFormSubmissionCompleted implements Action {
  readonly type = CANCEL_FORM_SUBMISSION_COMPLETED;

  constructor(public payload: IResponse<IFormSubmission>) {}
}

export class GetFormSubmissionHistory implements Action {
  readonly type = GET_FORM_SUBMISSION_HISTORY;

  constructor(public formSubmissionId: number) {}
}

export class GetFormSubmissionHistoryCompleted implements Action {
  readonly type = GET_FORM_SUBMISSION_HISTORY_COMPLETED;

  constructor(public payload: IFormSubmissionUserAction[]) {}
}

export class GetFormSubmission implements Action {
  readonly type = GET_FORM_SUBMISSION;
  constructor(
    public logbookId: number,
    public formSubmissionId: number,
    public dispatchHideLoader: boolean = false,
    public body?: IFormSubmissionRequestBody,
    public insertUpdatingLog: boolean = false,
  ) {}
}

export class GetFormSubmissionCompleted implements Action {
  readonly type = GET_FORM_SUBMISSION_COMPLETED;

  constructor(public payload: IHomeFormSubmission) {}
}

export class UpdateFormSubmission implements Action {
  readonly type = UPDATE_FORM_SUBMISSION;

  constructor(public logbookId: number, public formSubmissionId: number) {}
}

export class UpdateFormSubmissionCompleted implements Action {
  readonly type = UPDATE_FORM_SUBMISSION_COMPLETED;

  constructor(public payload: IHomeFormSubmission) {}
}

export class SetNetworkLogbooksTree implements Action {
  readonly type = SET_NETWORK_LOGBOOKS_TREE;

  constructor(public payload: IGenericTreeNode<ILogbook>[]) {}
}

export class SetSidebarCategoryExpandedStatus implements Action {
  readonly type = SET_SIDEBAR_CATEGORY_EXPANDED_STATUS;

  constructor(public payload: ISidebarCategoryExpandedStatus) {}
}

export class SetSidebarExpandedStatus implements Action {
  readonly type = SET_SIDEBAR_EXPANDED_STATUS;

  constructor(public payload: boolean) {}
}

export class GetLogbookUserActionData implements Action {
  readonly type = GET_LOGBOOK_USER_ACTION_DATA;

  constructor(public logbookId: number) {}
}

export class GetLogbookUserActionDataCompleted implements Action {
  readonly type = GET_LOGBOOK_USER_ACTION_DATA_COMPLETED;

  constructor(public payload: IGetManyResponse<IUserAction>) {}
}

export class GetFormUserActionData implements Action {
  readonly type = GET_FORM_USER_ACTION_DATA;

  constructor(public formId: number) {}
}

export class GetFormUserActionDataCompleted implements Action {
  readonly type = GET_FORM_USER_ACTION_DATA_COMPLETED;

  constructor(public payload: IGetManyResponse<IUserAction>, public formId: number) {}
}

export class GetFormSubmissions implements Action {
  readonly type = GET_FORM_SUBMISSIONS;

  constructor(public logbookId: number, public params: IFormSubmissionParams) {}
}

export class GetFormSubmissionsCompleted implements Action {
  readonly type = GET_FORM_SUBMISSIONS_COMPLETED;

  constructor(public payload: IGetManyResponse<IFormSubmission>) {}
}

export class GetLogbook implements Action {
  readonly type = GET_LOGBOOK;

  constructor(public logbookId: number) {}
}

export class GetLogbookCompleted implements Action {
  readonly type = GET_LOGBOOK_COMPLETED;

  constructor(public payload: IGetManyResponse<ILogbook>) {}
}

export class SetLogbookDetailToParentView implements Action {
  readonly type = SET_LOGBOOK_DETAIL_TO_PARENT_VIEW;

  constructor(public payload: boolean) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: ITableHeader[], public setAsDefault: boolean) {}
}

export class CreateFormSubmission implements Action {
  readonly type = CREATE_FORM_SUBMISSION;

  constructor(
    public logbookId: number,
    public formId: number,
    public form: any,
    public criticalityStatus: boolean,
    public issuerAndReason: IIssuerAndReason,
  ) {}
}

export class CreateFormSubmissionCompleted implements Action {
  readonly type = CREATE_FORM_SUBMISSION_COMPLETED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class SubmitFormSubmission implements Action {
  readonly type = SUBMIT_FORM_SUBMISSION;

  constructor(
    public formSubmissionId: number,
    public form: any,
    public criticalityStatus: number,
    public issuerAndReason: IIssuerAndReason,
  ) {}
}

export class SubmitFormSubmissionCompleted implements Action {
  readonly type = SUBMIT_FORM_SUBMISSION_COMPLETED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class SaveFormSubmission implements Action {
  readonly type = SAVE_FORM_SUBMISSION;

  constructor(
    public logbookId: number,
    public formSubmissionId: number,
    public form: any,
    public criticalityStatus: number,
    public issuerAndReason: IIssuerAndReason,
    public isCheckOut: boolean,
    public bulkCheckout: boolean,
  ) {}
}

export class SaveFormSubmissionCompleted implements Action {
  readonly type = SAVE_FORM_SUBMISSION_COMPLETED;

  constructor(public payload: IBaseOneResponse<any>) {}
}

export class RedirectToHome implements Action {
  readonly type = REDIRECT_TO_HOME;
}

export class GetFormVersion implements Action {
  readonly type = GET_FORM_VERSION;

  constructor(public formId: number, public formVersionId: number, public hideLoader: boolean = true) {}
}

export class GetFormVersionCompleted implements Action {
  readonly type = GET_FORM_VERSION_COMPLETED;

  constructor(public payload: IBaseOneResponse<IFormVersion>) {}
}

export class HomeSearch implements Action {
  readonly type = HOME_SEARCH;

  constructor(public searchKey: string) {}
}

export class SignDocument implements Action {
  readonly type = SIGN_DOCUMENT;

  constructor(public signFieldId: string) {}
}

export class HomeSearchLoaded implements Action {
  readonly type = HOME_SEARCH_LOADED;

  constructor(public payload: IHomeSearchResult[]) {}
}

export class GetCurrentUser implements Action {
  readonly type = GET_CURRENT_USER;

  constructor(public action: string) {}
}

export class SetHomeUserInfo implements Action {
  readonly type = SET_HOME_USER_INFO;

  constructor(public userInfo: IGetCurrentUserResponseData) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type HomeActions =
  | FavoriteLogbooksDataLoading
  | FavoriteLogbooksDataLoaded
  | OngoingLogbooksDataLoading
  | OngoingLogbooksDataLoaded
  | NetworkLogbooksDataLoading
  | NetworkLogbooksDataLoaded
  | ChildrenLogbooksDataLoading
  | ChildrenLogbooksDataLoaded
  | GridViewChanged
  | AddLogbookToFavoritesLoading
  | AddLogbookToFavoritesLoaded
  | LogbookHomeDetailDataLoading
  | LogbookHomeDetailDataLoaded
  | CancelFormSubmission
  | CancelFormSubmissionCompleted
  | GetFormSubmissionHistory
  | GetFormSubmissionHistoryCompleted
  | GetFormSubmission
  | GetFormSubmissionCompleted
  | UpdateFormSubmission
  | UpdateFormSubmissionCompleted
  | SetNetworkLogbooksTree
  | SetSidebarCategoryExpandedStatus
  | SetSidebarExpandedStatus
  | GetLogbookUserActionData
  | GetLogbookUserActionDataCompleted
  | GetFormUserActionData
  | GetFormUserActionDataCompleted
  | GetFormSubmissions
  | GetFormSubmissionsCompleted
  | GetLogbook
  | GetLogbookCompleted
  | SetLogbookDetailToParentView
  | SetTableSettings
  | CreateFormSubmission
  | CreateFormSubmissionCompleted
  | SubmitFormSubmission
  | SubmitFormSubmissionCompleted
  | SaveFormSubmission
  | SaveFormSubmissionCompleted
  | GetFormVersion
  | GetFormVersionCompleted
  | SignDocument
  | HomeSearch
  | HomeSearchLoaded
  | GetCurrentUser
  | SetHomeUserInfo
  | FetchError;
