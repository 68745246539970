import { TreeNode } from 'primeng/api';
import { IGenericObject } from '../model/interface/generic.model';
import * as _ from 'lodash';
import { IDatatableHeader, IDatatableOutputParameter } from '../component/datatable/datatable.model';
import { TrackByFunction, ViewChildDecorator } from '@angular/core';
import { ScwMatFormComponent } from '../component/scw-mat-ui/scw-mat-form/scw-mat-form.component';
import { IDatatableWithDraggableRowHeader, IFormIoFile, ITableHeader } from '../../../constants.model';
import { ScwMatDatepickerComponent } from '../component/scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.component';
import { ScwMatSelectComponent } from '../component/scw-mat-ui/scw-mat-select/scw-mat-select.component';
import { ScwMatInputComponent } from '../component/scw-mat-ui/scw-mat-input/scw-mat-input.component';
import { IGenericFilter, ITableQuery } from '../model/interface/common-page.model';
import {
  ILogbookMasterDataDetail,
  ILogbookMasterDataFields,
} from '../../store/settings/logbook-master-data/logbook-master-data.model';
import { IMasterDataOption, IModalContent } from '../../view/settings/logbook/logbook.model';
import moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IFieldVersion } from '../../view/reports/form-templates/form-templates.model';
import { ISort } from '../../view/settings/logbook-versions/logbook-versions.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {
  IFormMasterDataDetail,
  IFormMasterDataFields,
} from '../../store/settings/form-master-data/form-master-data.model';
import { IFormExcelData } from '../../store/forms/forms.model';
import { ILogbookExcelData } from '../../store/logbooks/logbooks.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IHomeFormDetail } from '../../store/home/home.model';
import { IFormSubmissionData } from '../../store/my-tasks/logs-form-entries/logs-form-entries.model';
import { lastValueFrom } from 'rxjs';
import { HelperService } from '../service/helper.service';

export class ComponentUtilities {
  public static findOneOptionForSelect<T>(collection: T[], value: unknown, key: string | number = 'id'): T[] {
    const item: T | undefined = collection.find((data: T) => key in data && data[key as keyof T] === value);

    return item !== undefined ? [item] : ([] as T[]);
  }

  public static makeTreeTableTreeFromArray(
    items: any[],
    parentKey: string = 'parentId',
    rootParentValue: unknown = '0',
    expanded: boolean = true,
    hasChildKey?: string,
  ): TreeNode[] {
    const map: IGenericObject<any> = {};
    const roots: any[] = [];

    const nodeArray: any[] = items.map((item) => {
      const itemProperties = { ...item };
      const children = itemProperties.children;
      delete itemProperties.children;
      return { children, data: { ...itemProperties } };
    });

    nodeArray.forEach((item, index) => {
      map[item['data'].id] = index;
      if (!item.children) {
        item.children = [];
      }

      item.expanded = expanded;

      if (hasChildKey) {
        item.leaf = !item.data[hasChildKey];
      }
    });

    nodeArray.forEach((node) => {
      if (node['data'][parentKey] !== rootParentValue && !_.isNil(nodeArray[map[node['data'][parentKey]]])) {
        nodeArray[map[node['data'][parentKey]]].children.push(node);
      } else {
        roots.push(node);
      }
    });

    nodeArray.forEach((node) => {
      if (node.children === undefined || node.children === null || node.children?.length === 0) {
        node.leaf = true;
      }
    });

    return roots;
  }

  public static readonly genericTrackByIndexFunction: TrackByFunction<any> = (index: number, _item: any) => {
    return index;
  };

  public static readonly genericTrackByItemFunction: TrackByFunction<any> = (_index: number, item: any) => {
    return item;
  };

  public static readonly datatableHeaderTrackBy: TrackByFunction<IDatatableHeader> = (
    index: number,
    item: IDatatableHeader,
  ) => {
    return `${item.value}_|_${index}` ?? item;
  };

  public static formatDatatableOutputParameterForGenericQuery<T>(
    params: IDatatableOutputParameter,
    overrideQuery?: Partial<ITableQuery> & T,
    defaultSort: ISort = { column: 'id', type: '-' },
  ): ITableQuery & T {
    const query: ITableQuery & T = {
      page: 1,
      rowsPerPage: 0,
      search: '',
      sort: {
        column: 'id',
        type: '-',
      },
      ...overrideQuery,
    } as ITableQuery & T;

    query.page = params.page ?? 1;
    query.rowsPerPage = params.rowsPerPage ?? 10;

    if (params.sort && params.sort.type !== 'none') {
      query.sort.column = params.sort.column;

      if (params.sort.type === 'ascending') {
        query.sort.type = '';
      }

      if (params.sort.type === 'descending') {
        query.sort.type = '-';
      }
    }

    if (params.sort && params.sort.type === 'none') {
      query.sort = defaultSort;
    }

    return query;
  }

  public static getFiltersFromFilterCardDropdownEvent(
    $event: IGenericObject<any>,
    castOptions?: any,
    forCustomQuery: boolean = false,
  ): IGenericFilter[] {
    return _.toPairs($event).reduce((dropdownFilters: IGenericFilter[], [key, value]) => {
      if (!_.isNil(key) && !_.isNil(value) && !_.isNil(_.fromPairs([key, value])) && Array.isArray(value)) {
        let generatedKey = key;
        if (!forCustomQuery) {
          generatedKey += '__in';
        }
        const filter: IGenericFilter = {
          field: generatedKey,
          values: value,
        };

        if (
          castOptions &&
          Array.isArray(castOptions['castToBoolean']) &&
          castOptions['castToBoolean'].includes(filter.field)
        ) {
          filter.values = filter.values.map((filterValue) => Boolean(filterValue));
        }

        if (
          castOptions &&
          Array.isArray(castOptions['castToNumber']) &&
          castOptions['castToNumber'].includes(filter.field)
        ) {
          filter.values = filter.values.map((filterValue) => Boolean(filterValue));
        }

        if (
          castOptions &&
          Array.isArray(castOptions['castToString']) &&
          castOptions['castToString'].includes(filter.field)
        ) {
          filter.values = filter.values.map((filterValue) => Boolean(filterValue));
        }
        dropdownFilters.push(filter);
      }

      return dropdownFilters;
    }, []);
  }

  public static getFilterFromFilterCardEventWithOmit($event: IGenericObject<any>, except: string[] = []) {
    return _.toPairs($event).reduce((dropdownFilters: IGenericFilter[], [key, value]) => {
      if (!_.isNil(key) && !_.isNil(value) && !_.isNil(_.fromPairs([key, value])) && !except.includes(key)) {
        if (key === 'dateRange' && typeof value.startDate !== 'undefined') {
          if (value.startDate instanceof moment) {
            value.startDate = value.startDate
              .utcOffset(-1 * value.startDate.tz('utc').utcOffset())
              .format('YYYY-MM-DD HH:mm:ss');
            value.endDate = value.endDate
              .utcOffset(-1 * value.endDate.tz('utc').utcOffset())
              .format('YYYY-MM-DD HH:mm:ss');
          }

          let dateFilter: IGenericFilter = {
            field: 'startDate',
            values: value.startDate,
          };
          dropdownFilters.push(dateFilter);

          dateFilter = {
            field: 'endDate',
            values: value.endDate,
          };
          dropdownFilters.push(dateFilter);
        } else {
          const filter: IGenericFilter = {
            field: key,
            values: value,
          };
          dropdownFilters.push(filter);
        }
      }

      return dropdownFilters;
    }, []);
  }

  public static readonly genericTrackByIdFunction: TrackByFunction<any> = (_index: number, item: any) => {
    return item.id ?? item;
  };

  public static readonly datatableWithDraggableRowHeaderTrackBy: TrackByFunction<IDatatableWithDraggableRowHeader> = (
    index: number,
    item: IDatatableWithDraggableRowHeader,
  ) => {
    return `${item.value}_|_${index}_|_${item.isOrderColumn}` ?? item;
  };

  public static getScwMatFormValuesWithDataPropertyKey(
    form: ScwMatFormComponent,
    setWithLodash: boolean = false,
  ): {
    data: IGenericObject<any>;
    dataAdditionalValues: IGenericObject<any>;
  } {
    const data: IGenericObject<any> = {};
    const dataAdditionalValues: IGenericObject<any> = {};

    form.inputs?.forEach((input) => {
      if (!input.dataPropertyKey) {
        return;
      }

      const setValue =
        _.isEmpty(input.inputModel) && typeof input.inputModel !== 'number'
          ? null
          : input.type === 'number' && !_.isNaN(Number(input.inputModel))
          ? Number(input.inputModel)
          : input.inputModel;

      ComponentUtilities.setValueForGetScwMatFormValuesWithDataPropertyKey(
        data,
        input.dataPropertyKey,
        setValue,
        setWithLodash,
      );
    });
    form.selects?.forEach((select) => {
      if (!select.dataPropertyKey || !select.primaryKey) {
        return;
      }

      const setValue = !select.inputModel?.length
        ? null
        : select.singleSelection
        ? select.inputModel[0]?.[select.primaryKey]
        : select.inputModel?.map((item: IGenericObject<any>) => (select.primaryKey ? item[select.primaryKey] : null));

      ComponentUtilities.setValueForGetScwMatFormValuesWithDataPropertyKey(
        data,
        select.dataPropertyKey,
        setValue,
        setWithLodash,
      );

      if (select.inputModel?.length) {
        ComponentUtilities.setValueForGetScwMatFormValuesWithDataPropertyKey(
          dataAdditionalValues,
          select.dataPropertyKey,
          select.singleSelection ? select.inputModel[0] : select.inputModel,
          setWithLodash,
        );
      }
    });
    form.datePickers?.forEach((datePiker) => {
      if (!datePiker.dataPropertyKey) {
        return;
      }

      const setValue = !datePiker.inputModel
        ? null
        : datePiker.singleDatePicker
        ? datePiker.inputModel.endDate?.utc().format()
        : datePiker.inputModel;
      ComponentUtilities.setValueForGetScwMatFormValuesWithDataPropertyKey(
        data,
        datePiker.dataPropertyKey,
        setValue,
        setWithLodash,
      );
    });

    return { data, dataAdditionalValues };
  }

  private static setValueForGetScwMatFormValuesWithDataPropertyKey(
    data: IGenericObject<any>,
    dataPropertyKey: string,
    setValue: any,
    setWithLodash: boolean,
  ): void {
    if (setWithLodash) {
      _.set(data, dataPropertyKey, setValue);
      return;
    }

    data[dataPropertyKey] = setValue;
  }

  public static setNullOrDefaultHiddenFormValues(
    form: ScwMatFormComponent,
    formData: any,
    formDataDropdownOptions: any,
    additionally: string[] = [],
  ): void {
    const visibleFromElements = [
      ...form.inputs.toArray(),
      ...form.selects.toArray(),
      ...form.datePickers.toArray(),
    ].map((item: ScwMatInputComponent | ScwMatSelectComponent | ScwMatDatepickerComponent) => {
      if (item.dataPropertyKey && additionally?.includes(item.dataPropertyKey)) {
        item?.reset();
      }

      return item.dataPropertyKey;
    });

    Object.keys(formData).forEach((key) => {
      if (visibleFromElements.includes(key) && !additionally?.includes(key)) {
        return;
      }

      ComponentUtilities.setNullOrDefaultFormValue(key, formData, formDataDropdownOptions);
    });
  }

  public static setNullOrDefaultFormValue(key: string, formData: any, formDataDropdownOptions: any): void {
    if ('defaultValue' in formData[key]) {
      formData[key].value = _.cloneDeep(formData[key].defaultValue);

      if (key in formDataDropdownOptions) {
        ComponentUtilities.modifyDropdownValueWithItsData(key, formData, formDataDropdownOptions);
      }

      return;
    }

    formData[key].value = null;
  }

  public static modifyDropdownValueWithItsData(key: string, formData: any, formDataDropdownOptions: any): any {
    formData[key].value = _.intersectionBy(formDataDropdownOptions[key], formData[key].value, 'id');

    return formData[key].value;
  }

  public static clearFormDataWithNullAndDefault(formData: any, formDataDropdownOptions: any): void {
    Object.keys(formData).forEach((key) =>
      ComponentUtilities.setNullOrDefaultFormValue(key, formData, formDataDropdownOptions),
    );
  }

  public static generateUserDefinedModalContent(modelContent: ILogbookMasterDataFields[] | undefined): IModalContent[] {
    return (
      modelContent?.map((item: any) => {
        return {
          fieldId: item.meta.fieldId,
          type: item.meta.type,
          options: item.meta.options,
          mandatory: item.meta.mandatory,
          maxLength: item.meta.maxLength,
          name: item.name,
          order: item.order,
          inputModel: ['Dropdown', 'DropdownMulti', 'CheckboxMulti'].includes(item.meta.type) ? [] : null,
        };
      }) ?? []
    );
  }

  public static showExcelToastMessage(
    count: number | undefined,
    toast: ToastrService,
    translate: TranslateService,
    itemType: string,
  ): void {
    toast.success(
      translate.instant('excel.messages.uploadSuccessWithDataCount', {
        count: Number(count),
        items: translate.instant(itemType),
      }),
      translate.instant('general.success'),
      {
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-right',
      },
    );
  }

  public static formatExcelUserDefinedFields(
    excelData: IFormExcelData | ILogbookExcelData,
    activeMasterDataData: IFormMasterDataDetail | ILogbookMasterDataDetail,
    translateService: TranslateService,
    userTimezone: string,
    userDateFormat: string,
    userTimeFormat: string,
  ): any[] {
    const data: any[] = [];
    const relaxedTimeFormat: string = userTimeFormat.replace('HH', 'H').replace('hh', 'h');
    const allowedFormats: string[] = [`${userDateFormat}`, `${userDateFormat} ${relaxedTimeFormat}`];

    activeMasterDataData?.fields?.forEach((item: IFormMasterDataFields) => {
      if (excelData.hasOwnProperty(`${item.meta['fieldId']}`)) {
        switch (item.meta['type']) {
          case 'Checkbox':
            let checkboxInputModel: string | number | null | boolean = excelData[item.meta['fieldId']];
            checkboxInputModel = checkboxInputModel && checkboxInputModel === 1 ? true : null;

            data.push({ fieldId: item.meta['fieldId'], inputModel: checkboxInputModel });
            break;
          case 'Dropdown':
          case 'DropdownMulti':
          case 'CheckboxMulti':
            data.push({
              fieldId: item.meta['fieldId'],
              inputModel: ComponentUtilities.findOptionDataForMultiSelects(
                excelData[item.meta['fieldId']],
                item.meta['fieldId'],
                activeMasterDataData,
                translateService,
                item.meta['type'],
              ),
            });
            break;
          case 'Datetime':
            if (excelData[item.meta['fieldId']]) {
              const dateInputFormat: string = 'YYYY-MM-DD HH:mm:ssssss';
              const dateDbFormat: string = 'YYYY-MM-DD HH:mm:ssssss [+00:00]';
              const formattedUserInput: string = moment
                .utc(excelData[item.meta['fieldId']], allowedFormats, true)
                .format(dateInputFormat);
              const userInputAsTimezoneMoment: moment.Moment = moment.tz(formattedUserInput, userTimezone);
              const userInputInUtcMoment: moment.Moment = userInputAsTimezoneMoment
                .clone()
                .subtract(userInputAsTimezoneMoment.utcOffset(), 'minute')
                .utc();
              const userInputInUtcString: string = userInputInUtcMoment.format(dateDbFormat);

              data.push({
                fieldId: item.meta['fieldId'],
                inputModel: userInputInUtcString
                  ? { startDate: userInputInUtcString, endDate: userInputInUtcString }
                  : null,
              });
            } else {
              data.push({
                fieldId: item.meta['fieldId'],
                inputModel: null,
              });
            }
            break;
          case 'Text':
          case 'TextArea':
            data.push({ fieldId: item.meta['fieldId'], inputModel: excelData[item.meta['fieldId']] });
            break;
          default:
        }
      }
    });
    return data;
  }

  public static findOptionDataForMultiSelects(
    selectedOptionsString: string,
    fieldId: string,
    activeMasterDataData: IFormMasterDataDetail | ILogbookMasterDataDetail,
    translate: TranslateService,
    fieldType: string,
  ): object[] {
    const selectedOptionsArray: IMasterDataOption[] = [];

    if (!activeMasterDataData.fields || !selectedOptionsString?.trim()) {
      return [];
    }

    activeMasterDataData.fields.forEach((item: ILogbookMasterDataFields) => {
      if (item.meta['fieldId'] === fieldId) {
        selectedOptionsString.split(', ').forEach((option: string) => {
          const selectItem: IMasterDataOption | undefined = _.find(item.meta['options'], { label: option });
          if (selectItem) {
            selectedOptionsArray.push({ ...selectItem, value: fieldType === 'CheckboxMulti' ?? null });
          } else {
            selectedOptionsArray.push({ id: -1, label: translate.instant('general.invalidOption'), status: false });
          }
        });
      }
    });
    return _.uniqBy(selectedOptionsArray, 'id');
  }

  public static showExcelError(
    modal: NgbModal,
    excelErrorModalTemplateRef: ViewChildDecorator,
    modalSize: string,
  ): void {
    modal.open(excelErrorModalTemplateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: modalSize,
    });
  }

  public static findFileToDownload(
    url: URL,
    selectedFormSubmission: IHomeFormDetail | IFormSubmissionData | null,
  ): IFormIoFile | null {
    if (!selectedFormSubmission) {
      return null;
    }

    const uniqueFileName: string = Object.fromEntries(url.searchParams)?.['form'];
    const formMetaObject: object =
      typeof selectedFormSubmission.meta === 'string'
        ? JSON.parse(selectedFormSubmission.meta)
        : selectedFormSubmission.meta;

    for (const metaField of Object.values(formMetaObject)) {
      if (!Array.isArray(metaField)) {
        continue;
      }

      const firstElement: IFormIoFile = _.first(metaField);

      if (!firstElement) {
        continue;
      }

      if ('name' in firstElement && uniqueFileName.includes(firstElement['name'])) {
        return firstElement;
      }
    }

    return null;
  }

  public static async downloadFormIoFile(
    url: URL,
    httpClient: HttpClient,
    helperService: HelperService,
    targetFile: IFormIoFile,
  ): Promise<void> {
    const blob: Blob = await lastValueFrom(
      httpClient.get(`${url.origin}/files/upload/${targetFile.data['id']}`, {
        responseType: 'blob',
      }),
    );

    helperService.saveData(blob, targetFile.originalName);
  }
}

export const formIoEditForm: any = {
  editForm: {
    textfield: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    textarea: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    number: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    checkbox: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    selectboxes: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    select: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    radio: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    button: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    email: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    url: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    phoneNumber: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    datetime: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    time: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    content: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    columns: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    panel: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    table: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
    file: [
      {
        key: 'display',
        components:[
          {
            key: 'modalEdit',
            disabled: true,
            ignore: true,
          },
        ],
      },
    ],
  },
};

export const formIoConfiguration: any = {
  ...formIoEditForm,
  noDefaultSubmitButton: true,
  builder: {
    basic: {
      title: 'Basic',
      weight: 0,
      default: true,
      key: 'basic',
      components: {
        textfield: {
          title: 'Text Field',
          icon: 'terminal',
          group: 'basic',
          documentation: 'http://help.form.io/userguide/#textfield',
          weight: 0,
          schema: {
            input: true,
            key: 'textField',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: true,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: true,
            modalEdit: false,
            label: 'TextField',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: {
              type: 'input',
            },
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
              minLength: '',
              maxLength: '',
              pattern: '',
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'textfield',
            mask: false,
            inputType: 'text',
            inputFormat: 'plain',
            inputMask: '',
            spellcheck: true,
          },
          key: 'textfield',
        },
        textarea: {
          title: 'TextArea',
          group: 'basic',
          icon: 'font',
          documentation: 'http://help.form.io/userguide/#textarea',
          weight: 20,
          schema: {
            input: true,
            key: 'textArea',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: true,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: true,
            modalEdit: false,
            label: 'TextArea',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: {
              type: 'input',
            },
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
              minLength: '',
              maxLength: '',
              pattern: '',
              minWords: '',
              maxWords: '',
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'textarea',
            mask: false,
            inputType: 'text',
            inputFormat: 'html',
            inputMask: '',
            spellcheck: true,
            rows: 3,
            wysiwyg: false,
            editor: '',
            fixedSize: true,
          },
          key: 'textarea',
        },
        number: {
          title: 'Number',
          icon: 'hashtag',
          group: 'basic',
          documentation: 'http://help.form.io/userguide/#number',
          weight: 30,
          schema: {
            input: true,
            key: 'number',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: true,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: false,
            modalEdit: false,
            label: 'Number',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: {
              type: 'input',
            },
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
              min: '',
              max: '',
              step: 'any',
              integer: '',
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'number',
          },
          key: 'number',
        },
        checkbox: {
          title: 'Checkbox',
          group: 'basic',
          icon: 'check-square',
          documentation: 'http://help.form.io/userguide/#checkbox',
          weight: 50,
          schema: {
            input: true,
            key: 'checkbox',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: true,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: false,
            modalEdit: false,
            label: 'Checkbox',
            labelPosition: 'right',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: null,
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'checkbox',
            inputType: 'checkbox',
            dataGridLabel: true,
            value: '',
            name: '',
          },
          key: 'checkbox',
        },
        selectboxes: {
          title: 'Select Boxes',
          group: 'basic',
          icon: 'plus-square',
          weight: 60,
          documentation: 'http://help.form.io/userguide/#selectboxes',
          schema: {
            input: true,
            key: 'selectBoxes',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: true,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: false,
            modalEdit: false,
            label: 'SelectBoxes',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: null,
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'selectboxes',
            inputType: 'radio',
            values: [
              {
                label: '',
                value: '',
              },
            ],
            fieldSet: false,
            inline: false,
          },
          key: 'selectboxes',
        },
        select: {
          title: 'Select',
          group: 'basic',
          icon: 'th-list',
          weight: 70,
          documentation: 'http://help.form.io/userguide/#select',
          schema: {
            input: true,
            key: 'select',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: true,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: true,
            modalEdit: false,
            label: 'Select',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: null,
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'select',
            data: {
              values: [],
              json: '',
              url: '',
              resource: '',
              custom: '',
            },
            clearOnRefresh: false,
            limit: 100,
            dataSrc: 'values',
            valueProperty: '',
            lazyLoad: true,
            filter: '',
            searchEnabled: true,
            searchField: '',
            minSearch: 0,
            readOnlyValue: false,
            authenticate: false,
            template: '<span>{{ item.label }}</span>',
            selectFields: '',
            searchThreshold: 0.3,
            uniqueOptions: false,
            fuseOptions: {
              include: 'score',
              threshold: 0.3,
            },
            customOptions: {},
          },
          key: 'select',
        },
        radio: {
          title: 'Radio',
          group: 'basic',
          icon: 'times-circle',
          weight: 80,
          documentation: 'http://help.form.io/userguide/#radio',
          schema: {
            input: true,
            key: 'radio',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: true,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: false,
            modalEdit: false,
            label: 'Radio',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: null,
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'radio',
            inputType: 'radio',
            values: [
              {
                label: '',
                value: '',
              },
            ],
            fieldSet: false,
          },
          key: 'radio',
        },
        button: {
          title: 'Button',
          group: 'basic',
          icon: 'stop',
          documentation: 'http://help.form.io/userguide/#button',
          weight: 110,
          schema: {
            input: true,
            key: 'custom',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: false,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: false,
            modalEdit: false,
            label: 'Custom',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: {
              type: 'input',
            },
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'button',
            size: 'md',
            leftIcon: '',
            rightIcon: '',
            block: false,
            action: 'custom',
            disableOnInvalid: false,
            theme: 'primary',
            dataGridLabel: true,
          },
          key: 'button',
        },
        password: false,
      },
      componentOrder: ['textfield', 'textarea', 'number', 'checkbox', 'selectboxes', 'select', 'radio', 'button'],
      subgroups: [],
    },
    advanced: {
      title: 'Advanced',
      weight: 10,
      key: 'advanced',
      components: {
        email: {
          title: 'Email',
          group: 'advanced',
          icon: 'at',
          documentation: 'http://help.form.io/userguide/#email',
          weight: 10,
          schema: {
            input: true,
            key: 'email',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: true,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: true,
            modalEdit: false,
            label: 'Email',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: {
              type: 'input',
            },
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
              minLength: '',
              maxLength: '',
              pattern: '',
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'email',
            mask: false,
            inputType: 'email',
            inputFormat: 'plain',
            inputMask: '',
            spellcheck: true,
            kickbox: {
              enabled: false,
            },
          },
          key: 'email',
        },
        url: {
          title: 'URL',
          group: 'advanced',
          icon: 'link',
          documentation: 'http://help.form.io/userguide/#url',
          weight: 20,
          schema: {
            input: true,
            key: 'url',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: true,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: true,
            modalEdit: false,
            label: 'URL',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: {
              type: 'input',
            },
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
              minLength: '',
              maxLength: '',
              pattern: '',
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'url',
            mask: false,
            inputType: 'url',
            inputFormat: 'plain',
            inputMask: '',
            spellcheck: true,
          },
          key: 'url',
        },
        phoneNumber: {
          title: 'Phone Number',
          group: 'advanced',
          icon: 'phone-square',
          weight: 30,
          documentation: 'http://help.form.io/userguide/#phonenumber',
          schema: {
            input: true,
            key: 'phoneNumber',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: true,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: true,
            modalEdit: false,
            label: 'PhoneNumber',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: {
              type: 'input',
            },
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
              minLength: '',
              maxLength: '',
              pattern: '',
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'phoneNumber',
            mask: false,
            inputType: 'tel',
            inputFormat: 'plain',
            inputMask: '(999) 999-9999',
            spellcheck: true,
          },
          key: 'phoneNumber',
        },
        datetime: {
          title: 'Date & Time',
          group: 'advanced',
          icon: 'calendar',
          documentation: 'http://help.form.io/userguide/#datetime',
          weight: 40,
          schema: {
            input: true,
            key: 'dateTime',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: '',
            protected: false,
            unique: false,
            persistent: true,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: false,
            modalEdit: false,
            label: 'DateTime',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: {
              type: 'input',
            },
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'datetime',
            format: 'yyyy-MM-dd hh:mm a',
            useLocaleSettings: false,
            allowInput: true,
            enableDate: true,
            enableTime: true,
            defaultDate: '',
            displayInTimezone: 'viewer',
            timezone: '',
            datepickerMode: 'day',
            datePicker: {
              showWeeks: true,
              startingDay: 0,
              initDate: '',
              minMode: 'day',
              maxMode: 'year',
              yearRows: 4,
              yearColumns: 5,
              minDate: null,
              maxDate: null,
            },
            timePicker: {
              hourStep: 1,
              minuteStep: 1,
              showMeridian: true,
              readonlyInput: false,
              mousewheel: true,
              arrowkeys: true,
            },
            customOptions: {},
          },
          key: 'datetime',
        },
        time: {
          title: 'Time',
          icon: 'clock',
          group: 'advanced',
          documentation: 'http://help.form.io/userguide/#time',
          weight: 55,
          schema: {
            input: true,
            key: 'time',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: true,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: true,
            modalEdit: false,
            label: 'Time',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: {
              type: 'input',
            },
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
              minLength: '',
              maxLength: '',
              pattern: '',
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'time',
            mask: false,
            inputType: 'time',
            inputFormat: 'plain',
            inputMask: '',
            spellcheck: true,
            format: 'HH:mm',
            dataFormat: 'HH:mm:ss',
          },
          key: 'time',
        },
        day: false,
        currency: false,
        survey: false,
        address: false,
        tags: false,
        signature: false,
      },
      componentOrder: ['email', 'url', 'phoneNumber', 'datetime', 'time'],
      subgroups: [],
    },
    layout: {
      title: 'Layout',
      weight: 20,
      key: 'layout',
      components: {
        content: {
          title: 'Content',
          group: 'layout',
          icon: 'code',
          preview: false,
          documentation: 'http://help.form.io/userguide/#content-component',
          weight: 5,
          schema: {
            input: false,
            key: 'content',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: true,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: false,
            modalEdit: false,
            label: 'Content',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: null,
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'content',
            html: '',
          },
          key: 'content',
        },
        columns: {
          title: 'Columns',
          icon: 'columns',
          group: 'layout',
          documentation: 'http://help.form.io/userguide/#columns',
          weight: 10,
          schema: {
            input: false,
            key: 'columns',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: false,
            hidden: false,
            clearOnHide: false,
            refreshOn: '',
            redrawOn: '',
            tableView: false,
            modalEdit: false,
            label: 'Columns',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: null,
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            tree: false,
            type: 'columns',
            columns: [
              {
                components: [],
                width: 6,
                offset: 0,
                push: 0,
                pull: 0,
                size: 'md',
              },
              {
                components: [],
                width: 6,
                offset: 0,
                push: 0,
                pull: 0,
                size: 'md',
              },
            ],
            autoAdjust: false,
            hideOnChildrenHidden: false,
          },
          key: 'columns',
        },
        panel: {
          title: 'Panel',
          icon: 'list-alt',
          group: 'layout',
          documentation: 'http://help.form.io/userguide/#panels',
          weight: 30,
          schema: {
            input: false,
            key: 'panel',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: false,
            hidden: false,
            clearOnHide: false,
            refreshOn: '',
            redrawOn: '',
            tableView: false,
            modalEdit: false,
            label: 'Panel',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: null,
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            tree: false,
            type: 'panel',
            title: 'Panel',
            theme: 'default',
            breadcrumb: 'default',
            components: [],
          },
          key: 'panel',
        },
        table: {
          title: 'Table',
          group: 'layout',
          icon: 'table',
          weight: 40,
          documentation: 'http://help.form.io/userguide/#table',
          schema: {
            input: false,
            key: 'table',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: false,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: false,
            modalEdit: false,
            label: 'Table',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: null,
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            tree: false,
            type: 'table',
            numRows: 3,
            numCols: 3,
            rows: [
              [
                {
                  components: [],
                },
                {
                  components: [],
                },
                {
                  components: [],
                },
              ],
              [
                {
                  components: [],
                },
                {
                  components: [],
                },
                {
                  components: [],
                },
              ],
              [
                {
                  components: [],
                },
                {
                  components: [],
                },
                {
                  components: [],
                },
              ],
            ],
            header: [],
            caption: '',
            cloneRows: false,
            striped: false,
            bordered: false,
            hover: false,
            condensed: false,
          },
          key: 'table',
        },
        htmlelement: false,
        fieldset: false,
        tabs: false,
        well: false,
      },
      componentOrder: ['content', 'columns', 'panel', 'table'],
      subgroups: [],
    },
    data: false,
    premium: {
      title: 'Premium',
      weight: 40,
      key: 'premium',
      components: {
        file: {
          title: 'File',
          group: 'premium',
          icon: 'file',
          documentation: 'http://help.form.io/userguide/#file',
          weight: 100,
          schema: {
            input: true,
            key: 'file',
            placeholder: '',
            prefix: '',
            customClass: '',
            suffix: '',
            multiple: false,
            defaultValue: null,
            protected: false,
            unique: false,
            persistent: true,
            hidden: false,
            clearOnHide: true,
            refreshOn: '',
            redrawOn: '',
            tableView: false,
            modalEdit: false,
            label: 'Upload',
            labelPosition: 'top',
            description: '',
            errorLabel: '',
            tooltip: '',
            hideLabel: false,
            tabindex: '',
            disabled: false,
            autofocus: false,
            dbIndex: false,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            widget: null,
            attributes: {},
            validateOn: 'change',
            validate: {
              required: false,
              custom: '',
              customPrivate: false,
              strictDateValidation: false,
              multiple: false,
              unique: false,
            },
            conditional: {
              show: null,
              when: null,
              eq: '',
            },
            overlay: {
              style: '',
              left: '',
              top: '',
              width: '',
              height: '',
            },
            allowCalculateOverride: false,
            encrypted: false,
            showCharCount: false,
            showWordCount: false,
            properties: {},
            allowMultipleMasks: false,
            type: 'file',
            image: false,
            privateDownload: false,
            imageSize: '200',
            filePattern: '*',
            fileMinSize: '0KB',
            fileMaxSize: '10MB',
            uploadOnly: false,
            storage: 'url',
            url: `${environment.apiUrl}/files/upload/`,
            options: JSON.stringify({
              withCredentials: true,
              headers: new HttpHeaders({ 'Content-Type': 'application/pdf' }),
            }),
          },
          key: 'file',
        },
        form: false,
        resource: false,
        unknown: false,
        recaptcha: false,
      },
      componentOrder: ['file'],
      subgroups: [],
    },
  },
  editForm: {
    url: [
      {
        key: 'data',
        components: [
          {
            key: 'multiple',
            hidden: true,
          },
        ],
      },
    ],
  },
};

export const filterCardClass: string = 'col-md-5 m-t-5 m-b-5';
export const filterCardClassForTabPages: string = 'col-md-5 m-t-4 m-b-5';
export type TFormIoComponentKey =
  | 'textfield'
  | 'textarea'
  | 'number'
  | 'checkbox'
  | 'selectboxes'
  | 'select'
  | 'radio'
  | 'button'
  | 'email'
  | 'url'
  | 'phoneNumber'
  | 'datetime'
  | 'time'
  | 'content'
  | 'columns'
  | 'panel'
  | 'table'
  | 'file';
export const formIoLayoutComponentTypes: TFormIoComponentKey[] = ['content', 'columns', 'panel', 'table'];
export const formIoNonFieldAreaComponentTypes: TFormIoComponentKey[] = [
  ...formIoLayoutComponentTypes,
  'checkbox',
  'selectboxes',
  'radio',
  'button',
  'file',
];
